import { COLUMNSHEADING } from '../constants';
import { TColumns } from '../types/types';
import getAllColumns from './getAllColumns';

export const getPodiumTitles = (sorting, TAB_ID): Array<TColumns> => {
  const allColumns = getAllColumns(sorting);
  const head = COLUMNSHEADING[TAB_ID].map(title => {
    if (title === 'Author') {
      return {
        ...allColumns[title],
        render(text, book) {
          if (book['CRM Author Id']) {
            return {
              props: {
                style: { background: 'rgb(158 255 201 / 38%)' }
              },
              children: <div>{text || ''}</div>
            };
          }
          return {
            children: text
          };
        }
      };
    }
    return allColumns[title];
  });

  return head;
};
